import React from "react";

export default function HireTechLeadersApproachFold({ para1, para2 }) {
  return (
    <div className="tech-leaders-approach-fold">
      <h2 className="tech-leaders-process-fold-title">
        Proven and Predictable Approach
      </h2>
      <p className="tech-leaders-approach-fold__desc">
        Making the right hiring decision is the difference between success and
        failure for every technology leader. Don&rsquo;t leave it to chance
      </p>
      {/* row1 */}
      <div className="row mx-0 flex-nowrap align-items-center justify-content-between tech-leaders-approach-fold__contentRow1">
        {/* colm1 */}
        <div className="tech-leaders-approach-fold__contentRow__colm">
          <div className="tech-leaders-approach-fold__contentRow__imgBlock1">
            <img
              src="/img/tech-leaders/tech-leaders-role-profiling-icon.svg"
              alt=""
            />
          </div>
          <h3 className="tech-leaders-fold1-block__title">
            In-depth Role Profiling
          </h3>
          <p className="tech-leaders-fold1-block__desc">{para1} </p>
        </div>
        {/* colm2 */}
        <div className="tech-leaders-approach-fold__contentRow__colm">
          <div className="tech-leaders-approach-fold__contentRow__imgBlock2">
            <img
              src="/img/tech-leaders/tech-leaders-talent-sourcing-icon.svg"
              alt=""
            />
          </div>
          <h3 className="tech-leaders-fold1-block__title">
            Superior Talent Sourcing
          </h3>
          <p className="tech-leaders-fold1-block__desc">{para2} </p>
        </div>
      </div>
      {/* row2 */}
      <div className="row mx-0 flex-nowrap align-items-center justify-content-between tech-leaders-approach-fold__contentRow2">
        {/* colm1 */}
        <div className="tech-leaders-approach-fold__contentRow__colm">
          <div className="tech-leaders-approach-fold__contentRow__imgBlock3">
            <img src="/img/tech-leaders/tech-leaders-graph-icon-3.svg" alt="" />
          </div>
          <h3 className="tech-leaders-fold1-block__title">
            Data-Driven Matching
          </h3>
          <p className="tech-leaders-fold1-block__desc">
            Our technology evaluates 15 key data-points to intelligently and
            accurately recommend not only top talent but the right talent based
            on your role specifications and hiring requirements.
          </p>
        </div>
        {/* colm2 */}
        <div className="tech-leaders-approach-fold__contentRow__colm">
          <div className="tech-leaders-approach-fold__contentRow__imgBlock4">
            <img
              src="/img/tech-leaders/tech-leaders-approach-icon-4.svg"
              alt=""
            />
          </div>
          <h3 className="tech-leaders-fold1-block__title">
            Comprehensive Screening
          </h3>
          <p className="tech-leaders-fold1-block__desc">
            Candidates are screened by real humans (not robots) against your
            unique requirements and hiring criteria to ensure they are a fit and
            meet or exceed your expectations
          </p>
        </div>
      </div>
    </div>
  );
}
