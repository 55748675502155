import React from "react";
import { Link } from "react-router-dom";
//img
//const img1 = "/img/footer/logo.png";
const img1 = "/img/logo/footer-aspire-logo.png";
//import img2 from "../assets/img/footer/f1.png";
//import img3 from "../assets/img/footer/f2.png";
const img4 = "/img/footer/f4.png";

const Webfooter = () => {
  return (
    <div className="footer-div column-div">
      {/**===============================  logo ===================================*/}
      <div className="footer-logo">
        <Link to="/">
          <img src={img1} alt="footer logo cursor-pointer" />
        </Link>
      </div>
      {/*<h5 className="text-center footer-17-font-bold">
        Lorem ipsum dolor sit amet, consectetur
        <br />
        adipiscing elit, sed do eiusmod tempor incididunt.
  </h5>*/}

      {/**===============================  title ===================================*/}

      <h4 className="footer-text1">Reduce Hiring Mistakes</h4>

      {/**===============================  btn ===================================*/}

      {/* <button className="footer-btn">Book a meeting</button> */}
      <a
        href="https://calendly.com/aspireandsucceedhr"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="footer-btn text-center">Book a meeting</div>
      </a>
      {/**===============================  link ===================================*/}
      <ul className="row mx-0 align-items-baseline justify-content-center footer-link-div w-100">
        <li className="footer-15-regular">
          <Link to="/">Home</Link>
        </li>
        <li className="footer-15-regular">
          <Link to="/about">About Us</Link>
        </li>

        {/*<h5 className="footer-15-regular">
          <Link to="/">Blogs</Link>
</h5>*/}

        <li className="footer-15-regular">
          <Link to="/our-services">Our Services</Link>
        </li>

        <li className="footer-15-regular">
          <Link to="/why-choose-us">Why Choose us?</Link>
        </li>

        {/*<li className="footer-15-regular">
          <Link to="/contact">Contact Us</Link>
</li>*/}

        {/* <div className="row mx-0 align-items-center pt-60">
               <div className="footer-face-img-div">
          <img src={img2} alt="facebook" className="img-fluid" />
        </div>
        <div className="footer-twitter-img-div">
          <img src={img3} alt="twitter" className="img-fluid" />
        </div>
        <div className="footer-insta-img-div">
          <img src={img4} alt="instagram" className="img-fluid" />
        </div>*/}

        <li className="footer-15-regular pr-0">Connect with us on LinkedIn</li>
        <a
          href={"https://www.linkedin.com/company/aspire-and-succeed-hr"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <li className="footer-linkedin-img-div ">
            <img src={img4} alt="linkedIn" className="img-fluid" />
          </li>
        </a>
      </ul>

      {/*</div>*/}
    </div>
  );
};

export default Webfooter;
