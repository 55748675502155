import React, { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WebNavbar from "../../WebNavbar";
import { useHistory } from "react-router-dom";

const img1 = "/img/assessments/a1.png";
const img3 = "/img/assessments/a2.png";
const img4 = "/img/assessments/a3.png";
const img5 = "/img/assessments/a4.png";
const img6 = "/img/assessments/a5.png";
const icon1 = "/img/assessments/trans.png";
const icon2 = "/img/assessments/pc.png";
const icon3 = "/img/assessments/brain.png";
const icon5 = "/img/assessments/icon1.png";
const icon6 = "/img/assessments/icon2.png";
const icon7 = "/img/assessments/icon3.png";
const back = "/img/icon/back.png";

const dummyData = [
  {
    imgPath: icon1,
    imgClass: "assessment-img1",
    name: "Language Test",
  },
  {
    imgPath: icon2,
    imgClass: "assessment-img2",
    name: "Programming Test",
  },
  {
    imgPath: icon3,
    imgClass: "assessment-img3",
    name: "Aptitude Test",
  },
];
const dummyData2 = [
  { name: "Java" },
  { name: "C / C++" },
  { name: "JavaScript" },
  { name: "Go" },
  { name: "Python" },
  { name: "PHP" },
];
const Assessments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const history = useHistory();

  const routeChange = () => {
    history.goBack();
  };

  const renderBlock2 = () => {
    return (
      <>
        <div className="row mx-0 align-items-center justify-content-between flex-nowrap pt-100">
          <div className="assessments-fold2-img-div">
            <img src={img3} alt="Test administration" className="img-fluid" />
          </div>
          <div className="no-show-management-card no-show-management-card--assessments-fold2">
            <div className="row mx-0 align-items-center">
              <div className="no-show-management-icon-div">
                <img
                  src={icon6}
                  alt="Test administration"
                  className="img-fluid"
                />
              </div>
              <h5 className="font-24-dms-medium pl-20">Test administration</h5>
            </div>
            <h5 className="font-20-mulish pt-20">
              Candidates write the test in Proctored environment
            </h5>
          </div>
        </div>
      </>
    );
  };
  const renderrBlock3 = () => {
    return (
      <>
        <div className="row mx-0 align-items-start flex-nowrap justify-content-end position-relative mt-100">
          <div className="no-show-management-card no-show-management-card--assessments-fold3 m-0">
            <div className="row mx-0 align-items-center">
              <div className="no-show-management-icon-div">
                <img src={icon7} alt="Test Report" className="img-fluid" />
              </div>
              <h5 className="font-24-dms-medium pl-20">Test Report</h5>
            </div>
            <h5 className="pt-10 font-20-mulish">
              Company can access the results via dedicated dashboard
            </h5>
          </div>
          <div className="assessments-fold3-img-div">
            <img src={img4} alt="Test administration" className="img-fluid" />
          </div>
        </div>
      </>
    );
  };
  const renderBlock4 = () => {
    return (
      <div>
        <div className="pt-50">
          <h3 className="font-36-DMsans-medium pl-60">Types of Test</h3>
        </div>
        <div className="mr-89">
          <div className="row mx-0 pt-60 justify-content-between assessment-type-test-div">
            {dummyData.map((data, key) => (
              <div key={key} className="assessments-icons-block">
                <div className="assessments-icon-block">
                  <div className={data.imgClass}>
                    <img
                      src={data.imgPath}
                      alt={data.name}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <h5 className="icon-text">{data.name}</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderBlock5 = () => {
    return (
      <div>
        <div className="pt-90">
          <h3 className="font-36-DMsans-medium pl-60">Most Popular Tests</h3>
        </div>
        <div className="pt-40 row mx-0 align-items-center flex-nowrap">
          <div className="assessments-fold5-img-div">
            <img src={img5} alt="Most Popular Tests" className="img-fluid" />
          </div>
          <div className="row mx-0 most-popular-test-outer-div">
            {dummyData2.map((data, key) => (
              <div key={key}>
                <div className="most-popular-test-inner-div">
                  <h5 className="most-popular-test-text">{data.name}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const renderBlock6 = () => {
    return (
      <div className="pb-100">
        <div className="pt-100 pl-174 ">
          <h3 className="font-36-DMsans-medium pl-60">Advantages</h3>

          <div className="pl-60 mx-0 row justify-content-between flex-nowrap align-items-start">
            <div className="no-show-management-card no-show-management-card--assessments-advantages">
              <h5 className="font-20-mulish--font-bold color-gray-opacity-5">
                Marked Reduction in Hiring Cycle
              </h5>
              {/*              <h5 className="font-20-mulish font-20-mulish-grey">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusm od tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad mi nim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </h5>
    
              <h5 className="font-17-dms">Learn more</h5>*/}{" "}
            </div>
            <div className="assessments-fold6-img-div">
              <img
                src={img6}
                alt="Marked Reduction in Hiring Cycle"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-div">
      <WebNavbar />
      <div className="assessments-div">
        <div
          className="row mx-0 align-items-center go-back-div"
          onClick={routeChange}
        >
          <img src={back} alt="go-back" className="go-back-img" />
          <span className="go-back-text">Go back</span>
        </div>
        <div className="row mx-0 align-items-center flex-nowrap justify-content-between">
          <div>
            <h2 className="font-24-DMsans-bold color-gray-opacity-5">
              Make sure you get qualified candidates
            </h2>
            <h5 className="font-50-poppins">Assessments</h5>
            <div className="pt-23">
              <h3 className="font-36-DMsans-medium pl-60">Process</h3>
            </div>
            <div className="no-show-management-card no-show-management-card--tittle">
              <div className="row mx-0 align-items-center">
                <div className="no-show-management-icon-div">
                  <img src={icon5} alt="Build Test" className="img-fluid" />
                </div>
                <h5 className="font-24-dms-medium pl-20">Build Test</h5>
              </div>
              <h5 className="font-20-mulish pt-10">
                Recruiter either uses predesigned tests or customizes as
                required
              </h5>
            </div>
          </div>
          <div className="assessments-fold1-img-div">
            <img src={img1} alt="assessments process" className="img-fluid" />
          </div>
        </div>
        {/* block 2 */}
        {renderBlock2()}
        {/* block 3 */}
        {renderrBlock3()}
        {/* block 4 */}
        {renderBlock4()}
        {/* block 5 */}
        {renderBlock5()}
      </div>
      {/* block 6 */}
      {renderBlock6()}
      {/*<div className="assessments-footer-div">*/}
      <Webfooter />
      {/*</div>*/}
    </div>
  );
};

export default Assessments;
