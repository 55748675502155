import React from "react";
import { Fragment } from "react";
import WebNavbar from "../../WebNavbar";

//array
const companyArrayOne = [
  {
    imgPath: "/img/about-new/godrej-logo.svg",
    imgClass: "godrej-logo",
    name: "godrej",
  },
  {
    imgPath: "/img/about-new/bosch-logo.svg",
    imgClass: "bosch-logo",
    name: "bosch",
  },
  {
    imgPath: "/img/about-new/panasonic-logo.svg",
    imgClass: "panasonic-logo",
    name: "panasonic",
  },
  {
    imgPath: "/img/about-new/monta-vista-logo.svg",
    imgClass: "monta-vista-logo",
    name: "montaVista",
  },
  {
    imgPath: "/img/about-new/shoptimize-logo.svg",
    imgClass: "shoptimize-logo",
    name: "shoptimize",
  },
];
const companyArrayTwo = [
  {
    imgPath: "/img/about-new/communitybrands-logo.svg",
    imgClass: "communitybrands-logo",
    name: "communitybrands logo",
  },
  {
    imgPath: "/img/about-new/toppr-logo.svg",
    imgClass: "toppr-logo",
    name: "toppr",
  },
  {
    imgPath: "/img/about-new/spotonbig-logo.svg",
    imgClass: "spotonbig-logo",
    name: "spotonbig",
  },
  {
    imgPath: "/img/about-new/empire-logo.svg",
    imgClass: "empire-logo",
    name: "empire",
  },
  {
    imgPath: "/img/about-new/hussman-logo.svg",
    imgClass: "hussman-logo",
    name: "hussman",
  },
];
const companyArrayThree = [
  {
    imgPath: "/img/about-new/handg-logo.svg",
    imgClass: "handg-logo",
    name: "handg logo",
  },
  {
    imgPath: "/img/about-new/shoppers-stop-logo.svg",
    imgClass: "shoppers-stop-logo",
    name: "shoppers stop",
  },
  {
    imgPath: "/img/about-new/company-logo.svg",
    imgClass: "company-logo",
    name: "company",
  },
  {
    imgPath: "/img/about-new/applied-ai-logo.svg",
    imgClass: "applied-ai-logo",
    name: "applied ai",
  },
];
export default function AboutUsNewFoldOne() {
  const renderBlockOne = () => {
    return (
      <div className="text-center about-us-new-fold-one-block-one">
        <h5 className="about-us-new-fold-one-block-one-text1"> We are</h5>
        <h3 className="about-us-new-fold-one-block-one-text2">
          Trusted by Leading Companies
        </h3>

        {/*========================== comapny array one =============================*/}

        <div className="row mx-0 align-items-center justify-content-center flex-nowrap pt-60">
          {companyArrayOne.map((data, index) => (
            <Fragment key={index}>
              <img
                className={data.imgClass}
                src={data.imgPath}
                alt={data.name}
              />
            </Fragment>
          ))}
        </div>

        {/*========================== comapny array two =============================*/}

        <div className="row mx-0 align-items-center justify-content-center flex-nowrap pt-40">
          {companyArrayTwo.map((data, index) => (
            <Fragment key={index}>
              <img
                className={data.imgClass}
                src={data.imgPath}
                alt={data.name}
              />
            </Fragment>
          ))}
        </div>

        {/*========================== comapny array three =============================*/}

        <div className="row mx-0 align-items-center justify-content-center flex-nowrap pt-60">
          {companyArrayThree.map((data, index) => (
            <Fragment key={index}>
              <img
                className={data.imgClass}
                src={data.imgPath}
                alt={data.name}
              />
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="about-us-new-fold-one-main-section">
      <WebNavbar activeLink={"about"} />
      <div className="row mx-0 align-items-center justify-content-between flex-nowrap about-us-new-fold-one-section">
        <img
          src={"/img/about-new/about-us-fold-one-img.png"}
          alt="about us"
          className="about-us-new-fold-one-img"
        />
        <div className="about-us-new-fold-one-title-div">
          <h1 className="about-us-new-fold-one-title">About Us</h1>
          <p className="about-us-new-fold-one-para pt-20">
            We’re on a mission to connect the best talent with
            <br /> the technology companies they love.
          </p>
          <p className="about-us-new-fold-one-para-two pt-50">
            Aspire provides a modern, science-based recruiting platform <br />
            for growth-stage technology &amp; sales organisations in North{" "}
            <br /> America, Europe and Asia.
          </p>
          <p className="about-us-new-fold-one-para-two pt-20">
            Powered by our proprietary technology, leading assessment
            <br /> science, and a Global recruiter network, we deliver qualified{" "}
            <br />
            candidates to power growth. Our proven system helps to <br />
            shorten &amp; Optimize recruitment cycle, reduce hiring mistakes
            <br /> and costly turnover by accurately predicting compatibility,{" "}
            <br />
            longevity, and success before hiring.
          </p>
        </div>
      </div>
      {renderBlockOne()}
    </div>
  );
}
