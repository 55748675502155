import React from "react";
import { Link } from "react-router-dom";

//img
const img1 = "/img/home/o1.png";
const img2 = "/img/home/o2.png";
const img3 = "/img/home/o3.png";
const img4 = "/img/home/o4.png";
const img5 = "/img/home/o5.png";

//array
const serivice = [
  {
    imgPath: img1,
    imgClass: "service-img1",
    name: "Complete HRD Management",
    link: "/hrd-management",
  },
  {
    imgPath: img2,
    imgClass: "service-img2",
    name: "Skills Assessment",
    link: "/assessments",
  },
  {
    imgPath: img3,
    imgClass: "service-img3",
    name: "No Show  Management",
    link: "/no-show-management",
  },
];

const array2 = [
  {
    imgPath: img4,
    imgClass: "service-img1",
    name: "Training and Hiring",
    link: "/training",
  },
  {
    imgPath: img5,
    imgClass: "service-img1",
    name: "Staffing Solutions",
    link: "/staffing-solutions",
  },
];

export default function AboutUsNewFoldTwo() {
  return (
    <div className="text-center about-us-new-fold-two-section">
      <h5 className="about-us-new-fold-one-block-one-text1">Here is how we</h5>
      <h3 className="about-us-new-fold-one-block-one-text2">
        Help you hire the best
      </h3>

      {/*====================== card row one ======================*/}

      <div className="our-service-card our-service-card--about-us row mx-0 flex-nowrap align-items-center justify-content-between ml-0">
        <div className="card-text-div--about-us">
          <h5 className="our-service-card--about-us-text1">
            Check out how Aspire Pro makes <br /> recruiting easy
          </h5>

          <h4
            className="learn-more-text text-left"
            onClick={() => window.location.replace("/#howAspireXProWorks")}
          >
            Learn more
          </h4>
        </div>
        <div className={"about-us-new-aspire-candidate-img"}>
          <img
            src={"/img/about-new/candidate-logo.svg"}
            alt="candidate-logo"
            className="img-fluid"
          />
        </div>
      </div>

      {/*====================== card row two ======================*/}

      <div className="row mx-0 justify-content-between flex-nowrap">
        {serivice.map((data, key) => (
          <div key={key} className="column-div our-service-card m-0">
            <div className="img-block-div">
              <div className={data.imgClass}>
                <img src={data.imgPath} alt={data.name} className="img-fluid" />
              </div>
            </div>
            <div className="card-text-div">
              <h5 className="our-service-card-name">{data.name}</h5>
            </div>
            <Link to={data.link}>
              <h4 className="learn-more-text">Learn more</h4>
            </Link>
          </div>
        ))}
      </div>

      {/*====================== card row three ======================*/}

      <div className="row mx-0 justify-content-center pt-20 flex-nowrap">
        {array2.map((data, key) => (
          <div key={key} className="column-div our-service-card ml-0">
            <div className="img-block-div">
              <div className={data.imgClass}>
                <img src={data.imgPath} alt={data.name} className="img-fluid" />
              </div>
            </div>
            <div className="card-text-div">
              <h5 className="our-service-card-name">{data.name}</h5>
            </div>
            <Link to={data.link}>
              <h4 className="learn-more-text">Learn more</h4>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
