import React from "react";

export default function HireTechLeadersFirstFold() {
  return (
    <div className="tech-leaders-fold1-block">
      <h2 className="tech-leaders-fold1-title">
        For Technology <br /> Leaders
      </h2>
      <div className="row mx-0 flex-nowrap align-items-start justify-content-between tech-leaders-fold1-block__contentRow">
        <div className="flex-shrink-0 tech-leaders-fold1-block__contentRow__colm1">
          {/* 1 */}
          <div className="row mx-0 flex-nowrap align-items-center tech-leaders-fold1-block__contentRow__colm1__row">
            <div className="tech-leaders-fold1-block__iconBlock">
              <img
                src="/img/tech-leaders/tech-leaders-clock-icon.svg"
                alt=""
                className="tech-leaders-fold1-block__iconBlock__img1"
              />
            </div>
            <div>
              <h3 className="tech-leaders-fold1-block__title">
                Save Time &amp; Money
              </h3>
              <p className="tech-leaders-fold1-block__desc">
                We handle the sourcing and screening so
                <br /> you can focus on the top candidates.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className="row mx-0 flex-nowrap align-items-center tech-leaders-fold1-block__contentRow__colm1__row">
            <div className="tech-leaders-fold1-block__iconBlock">
              <img
                src="/img/tech-leaders/tech-leaders-graph-icon-1.svg"
                alt=""
                className="tech-leaders-fold1-block__iconBlock__img2"
              />
            </div>
            <div>
              <h3 className="tech-leaders-fold1-block__title">
                Reduce hiring mistakes
              </h3>
              <p className="tech-leaders-fold1-block__desc">
                Our technology evaluates 15 key data-points to intelligently{" "}
                <br />
                and accurately recommend not only top talent but the right
                <br /> talent based on your role specifications and hiring{" "}
                <br />
                requirements.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className="row mx-0 flex-nowrap align-items-center tech-leaders-fold1-block__contentRow__colm1__row">
            <div className="tech-leaders-fold1-block__iconBlock">
              <img
                src="/img/tech-leaders/tech-leaders-graph-icon-2.svg"
                alt=""
                className="tech-leaders-fold1-block__iconBlock__img3"
              />
            </div>
            <div>
              <h3 className="tech-leaders-fold1-block__title">
                Improve Offer to Joining Ratio
              </h3>
              <p className="tech-leaders-fold1-block__desc">
                Tailored, auto pilot programme to engage the candidate post{" "}
                <br /> offer
              </p>
            </div>
          </div>
          {/* 4 */}
          <div className="row mx-0 flex-nowrap align-items-center tech-leaders-fold1-block__contentRow__colm1__row">
            <div className="tech-leaders-fold1-block__iconBlock">
              <img
                src="/img/tech-leaders/tech-leaders-graph-icon-3.svg"
                alt=""
                className="tech-leaders-fold1-block__iconBlock__img4"
              />
            </div>
            <div>
              <h3 className="tech-leaders-fold1-block__title">Hire faster</h3>
              <p className="tech-leaders-fold1-block__desc">
                Comprehensive tests to weed out low performers
              </p>
            </div>
          </div>
        </div>
        {/* flex-grow-1 row mx-0 align-items-center justify-content-center  */}
        <div className="tech-leaders-fold1-block__contentRow__colm2 text-center">
          {/* <p className="home-new-fold-one-talent-pipeline-para">
            Calendarly Widget
          </p> */}
          <img
            src={"/img/home-new/calendarly-img.png "}
            alt={"calendarly"}
            className="calendarly-img"
          />
          <div className="row mx-0 justify-content-center">
            <a
              href="https://calendly.com/aspireandsucceedhr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="text-center calendarly-link-btn">
                Get In touch - Book Meeting
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
