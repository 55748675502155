import React from "react";
import { Link } from "react-router-dom";

//img
//const img1 = "/img/home-new/home-new-fold-one.svg";
//const img1 = "/img/home-new/home-new-fold-one-new.png";
const img1 = "/img/home-new/home-new-fold-one-new-three.png";
const img2 = "/img/home/home-fold1.png";
const check = "/img/icon/check-icon.svg";

//array
const foldOneArray = [
  {
    title: "Technology Leaders",
    subTitle: "Build a Stronger Tech Team",
    para: "Get a steady pipeline of pre-screened and qualified developers to grow your development team.",
    link: "/tech-leaders",
  },
  {
    title: "Sales Leaders",
    subTitle: "Build a Stronger Sales Team",
    para: "Get a steady pipeline of pre-screened and qualified sales candidates to grow your sales.",
    link: "/sales-leaders",
  },
  {
    title: "Recruiters",
    subTitle: "Fill Open Positions Faster",
    para: "Reduce sourcing and screening time with pre-screened and qualified candidates that fit your hiring criteria and are ready to interview.",
    link: "/",
  },
];
export default function HomeNewFoldOne() {
  /*=============================================
                  renderBlockOne
    ==========================================*/
  const renderBlockOne = () => {
    return (
      <div className="home-new-fold-one-talent-pipeline-div">
        <h2 className="text-center home-our-service-title">
          {" "}
          Fill Your Talent Pipeline With Qualified
          <br /> Candidates
        </h2>
        <p className="text-center home-new-fold-one-talent-pipeline-para">
          No Sourcing. No Screening. No Headaches.
        </p>
        <div className="row mx-0 align-items-center justify-content-between flex-nowrap home-new-fold-one-technology-leader-div">
          <div className="home-new-fold-one-technology-leader-img-div">
            <img src={img2} alt="technology leader" className="img-fluid" />
          </div>
          <div>
            {foldOneArray.map((data, index) => (
              <div
                key={index}
                className="home-new-fold-one-technology-card-div row mx-0 align-items-start flex-nowrap"
              >
                <img
                  src={check}
                  alt=""
                  className="home-new-fold-one-check-icon"
                />
                <div>
                  <h4 className="home-new-fold-one-technology-card-title">
                    {data.title}
                  </h4>
                  <h5 className="home-new-fold-one-technology-card-subtitle">
                    {data.subTitle}
                  </h5>
                  <p className="home-new-fold-one-technology-card-para">
                    {data.para}
                  </p>
                  {index === 2 ? (
                    <span
                      className="home-new-fold-one-technology-card-link"
                      //onClick={scrollDiv}
                      onClick={() =>
                        window.location.replace("/#howAspireXProWorks")
                      }
                    >
                      Learn more
                    </span>
                  ) : (
                    <Link to={data.link}>
                      <span className="home-new-fold-one-technology-card-link">
                        Learn more
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="home-new-first-fold-div row mx-0 align-items-start justify-content-between flex-nowrap">
        <div>
          <h1 className="home-new-fold-one-title">
            Hire Better Technology <br /> &amp; Sales talent, faster!{" "}
          </h1>
          <p className="home-new-fold-one-para">
            Aspire Pro uses Data &amp; Science to deliver <br /> qualified
            candidates.
          </p>
          <div className="row mx-0 align-items-start justify-content flex-nowrap pt-50">
            <Link to="/tech-leaders">
              <div className="home-new-blue-btn">For Technology Leaders</div>
            </Link>
            <Link to="/sales-leaders">
              <div className="home-new-blue-btn home-new-blue-btn--green">
                For Sales Leader
              </div>
            </Link>
          </div>
        </div>
        <div className="home-new-fold-one-img-div">
          <img src={img1} alt="Hire Better Technology" />
        </div>
      </div>
      {renderBlockOne()}
    </>
  );
}
