import React from "react";
import WebNavbar from "../../WebNavbar";

export default function WhyChooseUsFoldOne() {
  return (
    <div className="choose-us-fold-one">
      <WebNavbar activeLink={"home"} isFooterLogo={true} />
      <div className="text-right pr-100 pt-100">
        {" "}
        <h1 className="choose-us-text1">We Make Hiring Talent Easy</h1>
        <p className="choose-us-text2 pt-20">
          Make the right hiring decisions with proven technology, science-based
          assessments, and <br /> specialized recruiters.
        </p>
      </div>
    </div>
  );
}
