import React from "react";

export default function HireTechLeadersProcessFold({ title, para }) {
  return (
    <div className="tech-leaders-process-fold">
      <h2 className="tech-leaders-process-fold-title">{title}</h2>
      <p className="text-center home-new-fold-one-talent-pipeline-para">
        Let us handle the entire recruiting process, so you can focus on hiring
        the best talent to hit your revenue goals
      </p>
      <div className="row mx-0 flex-nowrap align-items-start justify-content-center tech-leaders-process-fold__contentRow">
        <div className="tech-leaders-process-fold__contentRow__colm1">
          <div className="tech-leaders-process-fold__contentRow__imgBlock">
            <img
              src="/img/tech-leaders/tech-leaders-recruiting-services-icon.svg"
              alt=""
            />
          </div>
          <h3 className="tech-leaders-fold1-block__title">
            Recruiting Services
          </h3>
          <p className="tech-leaders-fold1-block__desc">
            Experienced talent advisors handle all the sourcing, screening, and
            scheduling so you can focus on hiring your next rock star.
          </p>
        </div>
        <div className="tech-leaders-process-fold__contentRow__colm2">
          <div className="tech-leaders-process-fold__contentRow__imgBlock">
            <img src="/img/tech-leaders/tech-leaders-graph-icon-3.svg" alt="" />
          </div>
          <h3 className="tech-leaders-fold1-block__title">Recruiting Tools</h3>
          <p className="tech-leaders-fold1-block__desc">
            We provide the skills assessments, Pre onboarding management to
            maximize your talent investment
          </p>
        </div>
      </div>
    </div>
  );
}
