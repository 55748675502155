import React from "react";

const technologyArray = [
  {
    imgPath: "/img/tech-leaders/dev-ops-icon.png",
    name: "Cloud DevOps Engineers",
    imgClassName: "dev-ops-icon",
  },
  {
    imgPath: "/img/tech-leaders/node-js-icon.svg",
    name: "Node JS Developers",
    imgClassName: "node-js-icon",
  },
  {
    imgPath: "/img/tech-leaders/php-icon.png",
    name: "PHP Developers",
    imgClassName: "php-icon",
  },
  {
    imgPath: "/img/tech-leaders/android-icon.svg",
    name: "Mobile application developers ",
    imgClassName: "android-icon",
  },
  {
    imgPath: "/img/tech-leaders/java-icon.svg",
    name: "Java Developers",
    imgClassName: "java-icon",
  },
  {
    imgPath: "/img/tech-leaders/java-script-icon.svg",
    name: "JavaScript Developers",
    imgClassName: "java-script-icon",
  },
  {
    imgPath: "/img/tech-leaders/angularjs-icon.png",
    name: "Full stack developer",
    imgClassName: "angularjs-icon",
  },
  {
    imgPath: "/img/tech-leaders/python-icon.png",
    name: "Python Developers",
    imgClassName: "python-icon",
  },
  {
    imgPath: "/img/tech-leaders/dba-icon.svg",
    name: "DBA",
    imgClassName: "dba-icon",
  },
  {
    imgPath: "/img/tech-leaders/ai-ml-expert-icon.svg",
    name: "AI/ML Experts",
    imgClassName: "ai-ml-icon",
  },
  {
    imgPath: "/img/tech-leaders/data-science-icon.svg",
    name: "Data Scientist",
    imgClassName: "data-science-icon",
  },
  {
    imgPath: "/img/tech-leaders/ui-ux-icon.svg",
    name: "UI/UX Designer",
    imgClassName: "ui-ux-icon",
  },
  {
    imgPath: "/img/tech-leaders/golang-icon.svg",
    name: "GoLang Developer",
    imgClassName: "golang-icon",
  },
  {
    imgPath: "/img/tech-leaders/qa-icon.svg",
    name: "QA Tester",
    imgClassName: "qa-icon",
  },
  {
    imgPath: "/img/tech-leaders/system-analyst-icon.svg",
    name: "System Analyst",
    imgClassName: "system-analyst-icon",
  },
  {
    imgPath: "/img/tech-leaders/aws-icon.svg",
    name: "Technical Architect",
    imgClassName: "aws-icon",
  },
];

export default function HireTechLeadersSpecializeInFoldNew() {
  return (
    <div className="tech-leaders-specialize-in-fold">
      <h2 className="tech-leaders-process-fold-title">
        Technology Positions We Specialize In
      </h2>
      <div className="row mx-0 align-items-start pt-100">
        {" "}
        {technologyArray.map((data, index) => (
          <div
            className="tech-leaders-specialize-in-fold-card text-center"
            key={index}
          >
            <div className="tech-leaders-specialize-in-fold-card-img">
              <img
                src={data.imgPath}
                className={data.imgClassName}
                alt={data.name}
              />
            </div>
            <h5 className="tech-leaders-specialize-in-fold-card-text">
              {data.name}
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
}
