import React from "react";
import WebNavbar from "../../WebNavbar";
import Webfooter from "../../Webfooter";
import HireTechLeadersProcessFold from "../hire-tech-leaders/HireTechLeadersProcessFold";
import HireTechLeadersApproachFold from "../hire-tech-leaders/HireTechLeadersApproachFold";
import HireSalesLeadersSpecializeInFold from "./HireSalesLeadersSpecializeInFold";
import HomeNewFoldThree from "../home-new/HomeNewFoldThree";
import HireSalesLeadersFirstFold from "./HireSalesLeadersFirstFold";

export default function HireSalesLeaders() {
  return (
    <>
      <WebNavbar />
      <HireSalesLeadersFirstFold />
      <HireTechLeadersProcessFold title={"We Make Hiring Sales Talent Easy"} />
      <HireTechLeadersApproachFold
        para1={
          "We go beyond the basic job description and define the skills, experience, capabilities, and behavioral traits candidates need to be successful in your unique selling environment and culture."
        }
        para2={
          "Leverage the power of our national recruiter network and peer-to-peer referral program to tap into the top 20% of sales talent in the market. This unique crowdsourcing approach enables us to fill your open roles faster."
        }
      />
      <HomeNewFoldThree
        isBgWhite={true}
        imgOne={"/img/home-new/sales-test-step-one.svg"}
        imgFour={"/img/home-new/sales-test-step-four.svg"}
        // para=""
        // title="How to Get Started"
        // stepOneTitle="Work With a Dedicated Talent Advisor to Define Your Ideal Candidate Profile and Hiring Criteria"
        stepTwoPara={
          "We use sophisticated technology along with a national network of experienced sales recruiters to filter through candidates and find the best of the best in your industry."
        }
        stepFourPara={
          "We put you in touch with elite salespeople by sending them right to your inbox for feedback and approval. Conveniently browse through candidates and choose the ones that have what you’re looking for. All the while our recruiting platform makes managing interview scheduling and feedback channels a breeze."
        }
      />
      <HireSalesLeadersSpecializeInFold />
      <div className="web-footer-div-tech-leaders-page">
        <Webfooter />
      </div>
    </>
  );
}
