import React from "react";
import ContactDetails from "../contact/ContactDetails";

export default function AboutUsNewFoldFour() {
  return (
    <div className="row mx-0 align-items-strat flex-nowrap about-us-new-fold-four-section">
      <div className="flex-grow-1 pt-60">
        <h2 className="about-us-new-fold-four-text1">Save Time &amp; Money</h2>
        <h5 className="about-us-new-fold-four-text2">
          Schedule a call and hire faster
        </h5>
        <a
          href="https://calendly.com/aspireandsucceedhr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="about-us-new-fold-four-btn text-center">
            Do it now!!
          </div>
        </a>
      </div>
      <div>
        <ContactDetails />
      </div>
    </div>
  );
}
