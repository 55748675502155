import React from "react";
import { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WhyChooseUsFoldOne from "./WhyChooseUsFoldOne";
import WhyChooseUsFoldThree from "./WhyChooseUsFoldThree";
import WhyChooseUsFoldTwo from "./WhyChooseUsFoldTwo";

export default function WhyChooseUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <WhyChooseUsFoldOne />
      <WhyChooseUsFoldTwo />
      <WhyChooseUsFoldThree />
      <Webfooter />
    </div>
  );
}
