import React, { Component } from "react";

export class Defult extends Component {
  render() {
    return (
      <div>
        <h5 className="text-center p-5 text-uppercase page_title">
          Page not found
        </h5>
      </div>
    );
  }
}

export default Defult;
