import React, { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WebNavbar from "../../WebNavbar";
import { useHistory } from "react-router-dom";

//img
const img1 = "/img/solution/fold1.png";
const icon1 = "/img/solution/icon1.png";
const icon2 = "/img/solution/icon2.png";
const icon3 = "/img/solution/icon3.png";
const icon4 = "/img/solution/icon4.png";
const icon5 = "/img/solution/icon5.png";
const back = "/img/icon/back.png";

//array
const dummyData = [
  {
    imgPath: icon1,
    name: "Database, Social Media, Reference  Recruiting ",
    desc: "Managed through our comprehensive databank across  industry segments. We also  extensively utilize social media  networking sites & references     ",
  },
  {
    imgPath: icon2,
    name: "Turnkey Recruiting",
    desc: "A specialized recruitment process that handles large volume  requirement from clients, especially at the junior/ middle management positions and is  best suited for start-ups/ new projects",
  },
  {
    imgPath: icon3,
    name: "Advertised Search",
    desc: "As desired by the client, or on the basis of sheer volume or  urgency of the need, we can release a creative recruitment advertisement (print/web)  announce jobs through SMS on behalf of the client to reach a larger target audience.",
  },
  {
    imgPath: icon4,
    name: "Executive Search ",
    desc: "To maintain a competitive edge, and  contribute  towards the long term business  growth we help",
  },
  {
    imgPath: icon5,
    name: "Customized  Hiring",
    desc: "A exclusively tailored Hiring solution which can include anything  desired by Client, hiring rates as per your requirements, psychometric tests,Technical  tests, handwriting analysis, Expert interviewers from related field , arrangement of  venue, interview Drives or any other requirement.",
  },
];

export default function StaffingSolutions() {
  //useEffect
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const history = useHistory();

  /*================================
            routeChange
  =================================*/
  const routeChange = () => {
    history.goBack();
  };

  /*================================
             return
  =================================*/
  return (
    <>
      <div className="min-100vh">
        <WebNavbar />
        <div className="staffing-top-div staffing-bg-div">
          <div
            className="row mx-0 align-items-center go-back-div"
            onClick={routeChange}
          >
            <img src={back} alt="go-back" className="go-back-img" />
            <span className="go-back-text">Go back</span>
          </div>

          <h3 className="font-24-DMsans-bold">Permanent</h3>
          <h2 className="font-50-poppins">Staffing Solutions</h2>
          <div className="row mx-0 justify-content-center pt-116">
            <div className="staffing-img-div">
              <img src={img1} alt="Staffing Solutions" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="row mx-0 justify-content-center staffing-card-div">
          {dummyData.map((data, key) => (
            <div key={key}>
              {key === 3 ? (
                <div className="staffing-card mr-130">
                  <div className="staffing-card-img-div">
                    <div className="staffing-card-img">
                      <img
                        src={data.imgPath}
                        className="img-fluid"
                        alt={data.name}
                      />
                    </div>
                  </div>
                  <div className="staffing-card-text-div ">
                    <h5 className="staffing-card-text">{data.name}</h5>
                  </div>
                  <div className="staffing-card-desc-div">
                    <h5 className="staffing-card-desc">{data.desc}</h5>
                  </div>
                </div>
              ) : (
                <div className="staffing-card mr-15">
                  <div className="staffing-card-img-div">
                    <div className="staffing-card-img">
                      <img
                        src={data.imgPath}
                        className="img-fluid"
                        alt={data.name}
                      />
                    </div>
                  </div>
                  <div className="staffing-card-text-div">
                    <h5 className="staffing-card-text">{data.name}</h5>
                  </div>
                  <div className="staffing-card-desc-div">
                    <h5 className="staffing-card-desc">{data.desc}</h5>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {/*<div className="training-footer-div">*/}
      <Webfooter />
      {/*</div>*/}
    </>
  );
}
