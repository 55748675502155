import React, { useEffect } from "react";
import WebNavbar from "../../WebNavbar";
import WebFooter from "../../Webfooter";
import { useHistory } from "react-router-dom";

//img
const back = "/img/icon/back.png";
const img1 = "/img/noshow/new-fold1.png";
const img2 = "/img/noshow/icon1.png";
const img3 = "/img/noshow/icon2.png";
const img4 = "/img/noshow/fold3.png";

export default function NewNoShowManagement() {
  //useEffect
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const history = useHistory();

  /*======================================
                 routeChange
  =======================================*/
  const routeChange = () => {
    history.goBack();
  };

  /*======================================
                 renderBlockOne
  =======================================*/
  const renderBlockOne = () => {
    return (
      <div className="row mx-0 align-items-center flex-nowrap pt-74 no-show-management-loosing-div">
        <img
          src={img1}
          alt="no show management fold one"
          className="new-no-show-management-fold1-img"
        />
        <div>
          <h4 className="no-show-management-font-20mulish-semibold">
            Loosing the talent between Acceptance and start date is a major risk
          </h4>
          <div className="no-show-management-card no-show-management-card--management-1">
            <div className="row mx-0 align-items-center">
              <div className="no-show-management-icon-div">
                <img src={img3} alt="How we help" className="img-fluid" />
              </div>
              <h5 className="font-24-dms-medium pl-20">How we help?</h5>
            </div>
            <div className=" pt-50">
              <h5 className="font-20-mulish font-20-mulish--no-show-management">
                Meet our Human like AI powered Virtual Assistant
              </h5>
            </div>
            <div className=" pt-30">
              <h5 className="font-20-mulish font-20-mulish--no-show-management">
                Our Virtual Assistant productively engages every single hired
                <br />
                resource during the pre-joining phase. Strengthens the Employer
                <br /> brand via best practices and Engages them socially . The
                assistant
                <br /> loops the hiring manager in the conversation if required
                or posts <br />
                periodic status to the Hiring Managers inbox about engagement
                activities.
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*======================================================
                        renderBlockTwo
  ======================================================*/
  const renderBlockTwo = () => {
    return (
      <div className="no-show-management-result-div">
        <div className="no-show-management-card no-show-management-card--management-2">
          <div className="row mx-0 align-items-start flex-nowrap">
            <div className="no-show-management-icon-div">
              <img
                src={img2}
                alt="no show management icon"
                className="img-fluid"
              />
            </div>
            <div className="pl-30">
              <h5 className="font-24-dms-medium pl-0">Result</h5>

              <h5 className="font-20-mulish pt-18 ">
                Higher Level of engagement and regular two way dialogue <br />
                makes the new talent a part of the family before joining.
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*======================================================
                        renderBlockThree
  ======================================================*/
  const renderBlockThree = () => {
    return (
      <div className="no-show-manageent-early-div row mx-0 align-items-center flex-nowrap">
        <div>
          <h4 className="no-show-management-font-36-dms-medium">
            Early Attrition
          </h4>
          <h5 className="font-20-mulish-semibold">
            Primary driving force of Early Attrition is mismatched expectations.
          </h5>
          <div className="pl-50">
            <div className="no-show-management-card no-show-management-card--management-1">
              <div className="row mx-0 align-items-center">
                <div className="no-show-management-icon-div">
                  <img src={img3} alt="How we help" className="img-fluid" />
                </div>
                <h5 className="font-24-dms-medium pl-20">How we help?</h5>
              </div>
              <div className=" pt-50">
                <h5 className="font-20-mulish font-20-mulish--no-show-management">
                  VA uses Aspire’s ‘AS’ assessment tools and tracks the progress
                  of new hires.
                </h5>
              </div>
              <div className=" pt-30">
                <h5 className="font-20-mulish font-20-mulish--no-show-management">
                  It then categorizes the resources as Happy, Unhappy or <br />
                  Disengaged and shares the insights in your inbox.{" "}
                </h5>
              </div>
            </div>
            <div className="no-show-management-card no-show-management-card--management-1">
              <div className="row mx-0 align-items-center">
                <div className="no-show-management-icon-div">
                  <img src={img2} alt="How we help" className="img-fluid" />
                </div>
                <h5 className="font-24-dms-medium pl-20">Result</h5>
              </div>
              <div className=" pt-10">
                <h5 className="font-20-mulish font-20-mulish--no-show-management font-20-mulish--no-show-management-result">
                  The company can work towards the dividing force and make sure
                  the recruit has no complaints
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="no-show-management-img-fold3-img-div">
          <img
            src={img4}
            alt="Early Attrition"
            className="no-show-management-img-fold3-img"
          />
        </div>
      </div>
    );
  };

  /*======================================================
                        render
  ======================================================*/
  return (
    <>
      <div className="bg-div min-100vh">
        <WebNavbar />
        <div className="no-show-management-div">
          <div
            className="row mx-0 align-items-center go-back-div"
            onClick={routeChange}
          >
            <img src={back} alt="go-back" className="go-back-img" />
            <span className="go-back-text">Go back</span>
          </div>

          <h3 className="font-24-DMsans-bold color-gray-opacity-5">
            How to deal with “No Show on DOJ &amp; Early Attrition”
          </h3>
          <h2 className="font-50-poppins">No Show Management</h2>
        </div>{" "}
        {renderBlockOne()}
        {renderBlockTwo()}
        {renderBlockThree()}
      </div>
      {/*<div className="no-show-management-footer">*/}
      <WebFooter />
      {/*</div>*/}
    </>
  );
}
