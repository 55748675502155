import React from "react";
import { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WebNavbar from "../../WebNavbar";
import ContactDetails from "./ContactDetails";
// import ContactForm from "./ContactForm";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <WebNavbar />
      <div className="contact-top-div">
        {/*         <h3 className="font-24-DMsans-bold ">We are here to help</h3>} */}
        <h3 className="font-24-DMsans-bold color-gray-opacity-5">
          We are here to help you hire the best
        </h3>
        <div className="row mx-0 align-items-start justify-content-between  flex-nowrap">
          <ContactDetails />
          {/* <ContactForm /> */}
          {/* <div className="flex-grow-0 row mx-0 align-items-center justify-content-center tech-leaders-fold1-block__contentRow__colm2 tech-leaders-fold1-block__contentRow__colm2--contact">
            <p className="home-new-fold-one-talent-pipeline-para">
              Calendarly Widget
            </p>
          </div> */}
          <div className="tech-leaders-fold1-block__contentRow__colm2 text-center">
            {/*<p className="home-new-fold-one-talent-pipeline-para">
            Calendarly Widget
  </p>*/}
            <img
              src={"/img/home-new/calendarly-img.png "}
              alt={"calendarly"}
              className="calendarly-img"
            />
            {/* link added */}
            <div className="row mx-0 justify-content-center">
              <a
                href="https://calendly.com/aspireandsucceedhr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text-center calendarly-link-btn">
                  Get In touch - Book Meeting
                </div>
              </a>
            </div>{" "}
          </div>
        </div>
      </div>
      <Webfooter />
    </div>
  );
}
