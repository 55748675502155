import React, { Fragment } from "react";

const deliverArray = [
  {
    text: "50%",
    para: "Faster new hire ramp up",
  },
  {
    text: "90%",
    para: "Searches end in a successful placement",
  },
  {
    text: "92%",
    para: "Candidates hired by us show up on the date of joining",
  },
];

export default function WhyChooseUsFoldThree() {
  return (
    <div className="choose-us-fold-three-section">
      <h2 className="choose-us-fold-two-title">We Deliver Results</h2>
      <div className="row mx-0 align-items-start choose-us-fold-three-content-section justify-content-between flex-nowrap">
        <img
          src={"/img/why-choose-us/fold-three-img.svg"}
          alt="We Deliver Results"
          className="choose-us-fold-three-img"
        />
        <div>
          {deliverArray.map((data, index) => (
            <Fragment key={index}>
              <div
                className={
                  index === 0
                    ? "row mx-0 align-items-start flex-nowrap"
                    : "row mx-0 align-items-start flex-nowrap pt-60"
                }
              >
                <div className="choose-fold-three-check-div">
                  <img
                    src={"/img/icon/blue-check-icon.svg"}
                    alt="check"
                    className="choose-blue-check-icon"
                  />
                  {index < 2 ? (
                    <div className="choose-fold-three-line"></div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <h4 className="choose-fold-three-text1">{data.text}</h4>
                  <p className="choose-fold-three-text2">{data.para}</p>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
