import React from "react";

export default function HireSalesLeadersFirstFold() {
  return (
    <div className="tech-leaders-fold1-block row mx-0 align-items-center justify-content-between flex-nowrap">
      <div>
        <h2 className="tech-leaders-fold1-title">For Sales Leaders</h2>
        {/* 1 */}
        <div className="pt-50">
          <div className="tech-leaders-fold1-block__iconBlock">
            <img
              src="/img/tech-leaders/tech-leaders-clock-icon.svg"
              alt=""
              className="tech-leaders-fold1-block__iconBlock__img1"
            />
          </div>
          <div className="sales-leaders-fold1-block__contentRow__colm1__row">
            <h3 className="sales-leaders-fold1-block__title">
              Save Time &amp; Money
            </h3>
            <p className="tech-leaders-fold1-block__desc">
              We handle the sourcing and screening so <br /> you can focus on
              the top candidates.{" "}
            </p>
          </div>
        </div>
        {/** 2 */}
        <div>
          <div className="tech-leaders-fold1-block__iconBlock">
            <img
              src="/img/tech-leaders/tech-leaders-graph-icon-1.svg"
              alt=""
              className="tech-leaders-fold1-block__iconBlock__img2"
            />
          </div>
          <div className="sales-leaders-fold1-block__contentRow__colm1__row">
            <h3 className="sales-leaders-fold1-block__title">
              Reduce hiring mistakes
            </h3>
            <p className="tech-leaders-fold1-block__desc">
              Our technology evaluates 15 key data-points to intelligently{" "}
              <br />
              and accurately recommend not only top talent but the right
              <br /> talent based on your role specifications and hiring <br />
              requirements.
            </p>
          </div>
        </div>
        {/** 3 */}
        <div>
          <div className="tech-leaders-fold1-block__iconBlock">
            <img
              src="/img/tech-leaders/tech-leaders-graph-icon-2.svg"
              alt=""
              className="tech-leaders-fold1-block__iconBlock__img3"
            />
          </div>
          <div className="sales-leaders-fold1-block__contentRow__colm1__row">
            <h3 className="sales-leaders-fold1-block__title">
              Improve Offer to Joining Ratio
            </h3>
            <p className="tech-leaders-fold1-block__desc">
              Tailored, auto pilot programme to engage the candidate post <br />
              offer
            </p>
          </div>
        </div>
        {/** 4 */}
        <div>
          <div className="tech-leaders-fold1-block__iconBlock">
            <img
              src="/img/tech-leaders/tech-leaders-graph-icon-3.svg"
              alt=""
              className="tech-leaders-fold1-block__iconBlock__img4"
            />
          </div>
          <div className="sales-leaders-fold1-block__contentRow__colm1__row">
            <h3 className="sales-leaders-fold1-block__title">Hire faster</h3>
            <p className="tech-leaders-fold1-block__desc">
              Comprehensive tests to weed out low performers
            </p>
          </div>
        </div>
      </div>
      {/** Calendarly Widget */}
      {/* row mx-0 align-items-center justify-content-center  */}
      <div className="tech-leaders-fold1-block__contentRow__colm2__outer-div">
        <div className="tech-leaders-fold1-block__contentRow__colm2 text-center">
          {/*<p className="home-new-fold-one-talent-pipeline-para">
            Calendarly Widget
  </p>*/}
          <img
            src={"/img/home-new/calendarly-img.png "}
            alt={"calendarly"}
            className="calendarly-img"
          />
          <div className="row mx-0 justify-content-center">
            <a
              href="https://calendly.com/aspireandsucceedhr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="text-center calendarly-link-btn">
                Get In touch - Book Meeting
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
