import React from "react";

//img
// const img1 = "/img/home-new/aspire-test-step-one.png";
// const img2 = "/img/home-new/aspire-test-step-two.png";
// const img3 = "/img/home-new/aspire-test-step-three.png";
// const img4 = "/img/home-new/aspire-test-step-four.png";
// const img1 = "/img/home-new/aspire-test-step-one-new.svg";
const img2 = "/img/home-new/aspire-test-step-two-new.svg";
const img3 = "/img/home-new/aspire-test-step-three-new.svg";
//const img4 = "/img/home-new/aspire-test-step-four-new.svg";
const img5 = "/img/home-new/aspire-test-step-five.svg";

export default function HomeNewFoldThree({
  // title,
  // para,
  // stepOneTitle,
  isBgWhite,
  isFiveFoldBgWhite,
  imgOne,
  imgFour,
  stepTwoPara,
  stepFourPara,
}) {
  /*======================================
                renderBlockOne
    ======================================*/
  const renderBlockOne = () => {
    return (
      <div className="row pt-100 mx-0 align-items-end justify-content-center flex-nowrap">
        <div>
          <img
            src={imgOne}
            alt="step one"
            className="home-new-fold-three-step-one"
          />
        </div>
        <div>
          <h2 className="home-new-fold-three-step-number">Step 01</h2>
          <h3 className="home-new-fold-three-step-title pt-50">
            {/* {stepOneTitle} */}
            Register for Your Free Account and Work with a Talent Advisor to
            Define Your Ideal Candidate Profile and Hiring Criteria
          </h3>
          <p className="home-fold-two-card-para home-fold-two-card-para--fold-three pt-18">
            A “one size fits all” approach won’t deliver the results you need.
            We provide tailored candidate profiling by identifying the criteria
            that separates your top performers from bottom performers using
            proven science.
          </p>
        </div>
      </div>
    );
  };
  /*======================================
                renderBlockTwo
    ======================================*/
  const renderBlockTwo = () => {
    return (
      <div className="row mx-0 flex-nowrap justify-content-between align-items-start home-new-fold-three-step-2-div">
        <div>
          <h2 className="home-new-fold-three-step-number">Step 02</h2>
          <h3 className="home-new-fold-three-step-title pt-50">
            Our Powerful Technology Identifies and Engages Active and Passive
            Candidates That Match Your Criteria
          </h3>
          <p className="home-fold-two-card-para home-fold-two-card-para--fold-three pt-18">
            {stepTwoPara}
          </p>
        </div>
        <img
          src={img2}
          alt="step two"
          className="home-new-fold-three-step-two-img"
        />
      </div>
    );
  };
  /*======================================
                renderBlockThree
    ======================================*/
  const renderBlockThree = () => {
    return (
      <div className="row mx-0 align-items-start justify-content-between flex-nowrap home-new-fold-three-step-three-div">
        <div>
          <img
            src={img3}
            alt="step three"
            className="home-new-fold-three-step-three"
          />
        </div>
        <div>
          <h2 className="home-new-fold-three-step-number pt-40">Step 03</h2>
          <h3 className="home-new-fold-three-step-title pt-50">
            Candidates are Screened and Assessed Using Data and Science to
            Predict Success Before Hiring
          </h3>
          <p className="home-fold-two-card-para home-fold-two-card-para--fold-three pt-18">
            Out team will phone screen and use a custom Aspire Pro assesment to
            gauge a candidate's technical skills and capabilities. This ensures
            they can walk the talk. Not just talk the talk.
          </p>
        </div>
      </div>
    );
  };
  /*======================================
                renderBlockTwo
    ======================================*/
  const renderBlockFour = () => {
    return (
      <div className="row mx-0 flex-nowrap justify-content-between align-items-start home-new-fold-three-step-four-div">
        <div>
          <h2 className="home-new-fold-three-step-number">Step 04</h2>
          <h3 className="home-new-fold-three-step-title pt-50">
            Qualified Candidates are Delivered Right to Your Inbox for Feedback
            and Approval
          </h3>
          <p className="home-fold-two-card-para home-fold-two-card-para--fold-three pt-30">
            {stepFourPara}
          </p>
        </div>
        <img
          src={imgFour}
          alt="step four"
          className="home-new-fold-three-step-four-img"
        />
      </div>
    );
  };

  /*======================================
                renderBlockFive
    ======================================*/
  const renderBlockFive = () => {
    return (
      <div
        className={
          isFiveFoldBgWhite
            ? "row mx-0 align-items-start justify-content-between home-new-fold-three-step-five-div flex-nowrap bg-white"
            : "row mx-0 align-items-start justify-content-between home-new-fold-three-step-five-div flex-nowrap"
        }
      >
        <div>
          <img
            src={img5}
            alt="step five"
            className="home-new-fold-three-step-five"
          />
        </div>
        <div>
          <h2 className="home-new-fold-three-step-number pt-30">Step 05</h2>
          <h3 className="home-new-fold-three-step-title pt-50">
            Interview and Select the Candidates You Love – We'll manage No Show
            on DOJ &amp; Early Attrition
          </h3>
          <p className="home-fold-two-card-para home-fold-two-card-para--fold-three pt-18">
            Our Virtual Assistant productively engages every single hired
            resource during the pre-joining phase and strengthens the Employer
            brand via best practices.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isBgWhite ? "bg-white home-new-fold-three" : "home-new-fold-three"
      }
    >
      <div className="text-center">
        <h2 className="home-our-service-title">
          {/* {title} */}
          How Aspire Pro Works{" "}
        </h2>
        {/* {para && ( */}
        <p className="home-new-fold-one-talent-pipeline-para">
          {/* {para} */}
          We aim to make the hiring process as simple and transparent as
          possible from start to finish.{" "}
        </p>
        {/* )} */}
      </div>
      {renderBlockOne()}
      {renderBlockTwo()}
      {renderBlockThree()}
      {renderBlockFour()}
      {renderBlockFive()}
    </div>
  );
}
