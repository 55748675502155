import React from "react";

export default function WhyChooseUsFoldTwo() {
  /*===============================================
                  renderBlockOne
=================================================*/
  const renderBlockOne = () => {
    return (
      <div className="row mx-0 align-items-start flex-nowrap choose-us-fold-two-block-one">
        <img
          src={"/img/why-choose-us/fold-two-img-one.svg"}
          alt="A Modern Approach to Engage Today's Modern Talent "
          className="choose-us-fold-two-img-one"
        />
        <div className="pt-40">
          <h3 className="choose-us-fold-two-text1">
            A Modern Approach to Engage <br /> Today's Modern Talent
          </h3>
          <p className="choose-us-fold-two-text2 pt-20">
            We use a proprietary hiring platform combined with powerful AI
            matching technology to engage top talent that fits your ideal
            candidate profile and environment for that perfect fit.
          </p>
        </div>
      </div>
    );
  };

  /*===============================================
                  renderBlockTWo
=================================================*/
  const renderBlockTwo = () => {
    return (
      <div className="row mx-0 align-items-start flex-nowrap choose-us-fold-two-block-two">
        <div className="pt-40">
          <h3 className="choose-us-fold-two-text1">
            National Recruiting and Referal <br />
            network for Technology &amp; Sales
            <br /> Hiring
          </h3>
          <p className="choose-us-fold-two-text2 choose-us-fold-two-text2--two pt-20">
            Fill your pipeline with qualified candidates faster with our unique
            crowdsourcing and candidate referral model that delivers unmatched
            access to a large network of professionals that match your unique
            profile and hiring requirements.
          </p>
        </div>
        <img
          src={"/img/why-choose-us/fold-two-img-two.svg"}
          alt="National Recruiting and Referal network for Technology &amp; Sales Hiring "
          className="choose-us-fold-two-img-two"
        />{" "}
      </div>
    );
  };

  /*===============================================
                  renderBlockThree
=================================================*/
  const renderBlockThree = () => {
    return (
      <div className="row mx-0 align-items-start justify-content-between flex-nowrap choose-us-fold-two-block-three">
        <img
          src={"/img/why-choose-us/fold-two-img-three.png"}
          alt="The Industries Leading Pre-Interview Predictive Technology &amp; Sales Assessment"
          className="choose-us-fold-two-img-three"
        />{" "}
        <div>
          <h3 className="choose-us-fold-two-text1 pt-40">
            The Industries Leading Pre-
            <br />
            Interview Predictive Technology
            <br /> &amp; Sales Assessment{" "}
          </h3>
          <p className="choose-us-fold-two-text2 pt-20">
            Custom designed by Technology and Sales leaders, Aspire Pro
            assessments identify top 10% of the applicants.{" "}
          </p>
        </div>
      </div>
    );
  };
  /*===============================================
                  renderBlockFour
=================================================*/
  const renderBlockFour = () => {
    return (
      <div className="row mx-0 align-items-center justify-content-between flex-nowrap choose-us-fold-two-block-four">
        <div>
          <h3 className="choose-us-fold-two-text1">
            We go beyond recruiting, <br />
            Aspire Virtual Assistant helps
            <br /> to manage candidate backouts <br />
            on the Date of Joining &amp;
            <br /> Reduce Early Attrition{" "}
          </h3>
          <p className="choose-us-fold-two-text2 pt-30">
            Improve offer to Joining ratio, Aspire Virtual Assistant
            productively engages every single hired resource from pre-joining
            phase. Strengthen your Employer brand via best practices and improve
            retention.{" "}
          </p>
        </div>
        <img
          src={"/img/why-choose-us/fold-two-img-four.svg"}
          alt="We go beyond recruiting, Aspire Virtual Assistant helps to manage candidate backouts on the Date of Joining & Reduce Early Attrition"
          className="choose-us-fold-two-img-four"
        />{" "}
      </div>
    );
  };
  /*===============================================
                  return
=================================================*/
  return (
    <div className="choose-us-fold-two-section">
      <h2 className="choose-us-fold-two-title">
        We Provide a Unique Blend of Technology and
        <br /> Science with a Human Touch
      </h2>
      {renderBlockOne()}
      {renderBlockTwo()}
      {renderBlockThree()}
      {renderBlockFour()}
    </div>
  );
}
