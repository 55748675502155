import React from "react";

export default function HireSalesLeadersSpecializeInFold() {
  const checkIcon = (
    <img src="/img/tech-leaders/tech-leaders-check-in-circle-icon.svg" alt="" />
  );

  return (
    <div className="sales-leaders-specialize-in-fold">
      <h2 className="tech-leaders-process-fold-title">
        We specialize in recruiting technology sales talent for individual
        contributor and sales leadership positions.
      </h2>

      <div className="row mx-0 flex-nowrap align-items-center tech-leaders-specialize-in-fold__listContent">
        <div className="tech-leaders-process-fold__listContent__colm1">
          <h3 className="tech-leaders-fold1-block__title">
            Individual Contributor
          </h3>
          <ul>
            <li>{checkIcon}Sales Development</li>
            <li>{checkIcon}Business Development</li>
            <li>{checkIcon}Inside Sales</li>
            <li>{checkIcon}Account Executive</li>
            <li>{checkIcon}Account Manager</li>
          </ul>
        </div>
        <div className="tech-leaders-process-fold__listContent__colm2">
          <h3 className="tech-leaders-fold1-block__title">Sales Leadership</h3>
          <ul className="mr-0">
            <li>{checkIcon}Chief Revenue Officer</li>
            <li>{checkIcon}Chief Sales Officer</li>
            <li>{checkIcon}VP of Sales</li>
            <li>{checkIcon}Director of Sales</li>
            <li>{checkIcon}Sales Manager</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
