import React, { Component } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Defult from "./component/Defult";
import "font-awesome/css/font-awesome.min.css";
import "font-awesome/less/font-awesome.less";
//import Home from "./component/desktop/home/Home";
import NewNoShowManagement from "./component/desktop/our-services/NewNoShowManagement";
import Assessments from "./component/desktop/our-services/Assessments";
import Training from "./component/desktop/our-services/Training";
import StaffingSolutions from "./component/desktop/our-services/StaffingSolutions";
import HRDManagement from "./component/desktop/our-services/HRDManagement";
//import AboutUs from "./component/desktop/AboutUs";
import Contact from "./component/desktop/contact/Contact";
import OurServices from "./component/desktop/our-services/OurServices";
import HomeNew from "./component/desktop/home-new/HomeNew";
import HireTechLeaders from "./component/desktop/hire-tech-leaders/HireTechLeaders";
import HireSalesLeaders from "./component/desktop/hire-sales-leaders/HireSalesLeaders";
import WhyChooseUs from "./component/desktop/why-choose-us/WhyChooseUs";
import AboutUsNew from "./component/desktop/about-us/AboutUsNew";

export class App extends Component {
  constructor() {
    super();
    this.state = {
      // require for responsive window
      windowWidth: window.innerWidth,
    };
  }

  /*========================================================
                mobile view event handlers
  ========================================================*/
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  /*========================================================
                end mobile view event handlers
  ========================================================*/

  render() {
    return (
      <div>
        <Router>
          <Switch>
            {/*<Route exact path="/" component={Home} />*/}
            <Route exact path="/" component={HomeNew} />
            <Route exact path="/tech-leaders" component={HireTechLeaders} />
            <Route exact path="/sales-leaders" component={HireSalesLeaders} />
            <Route
              exact
              path="/no-show-management"
              component={NewNoShowManagement}
            />
            <Route exact path="/assessments" component={Assessments} />
            <Route exact path="/training" component={Training} />
            <Route
              exact
              path="/staffing-solutions"
              component={StaffingSolutions}
            />
            <Route exact path="/hrd-management" component={HRDManagement} />
            <Route exact path="/about" component={AboutUsNew} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/our-services" component={OurServices} />
            <Route exact path="/why-choose-us" component={WhyChooseUs} />
            <Route component={Defult} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
