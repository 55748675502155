import React from "react";
import { Link } from "react-router-dom";

//img
const img1 = "/img/home-new/top-talent.svg";
const img2 = "/img/home-new/intelligent.svg";
const img3 = "/img/home-new/science-based.svg";
const check = "/img/icon/check-icon.svg";
//const img4 = "/img/home-new/home-fold-two-confidance.png";
const img4 = "/img/home-new/home-fold-two-confidance-new.svg";

//array
const arrayData = [
  {
    imgPath: img1,
    imgClass: "home-new-fold-two-card-img1",
    title: "Sourcing Top Talent",
    para: "Our platform connects you with a verified national network of recruitment experts that have a local talent pool to consistently generate top-level candidates to fill your open sales roles faster",
  },
  {
    imgPath: img2,
    imgClass: "home-new-fold-two-card-img2",
    title: "Intelligent Matching",
    para: "We use cutting-edge technology that analyzes 15 key data points to identify the best of the best candidates that perfectly match your role and hiring criteria. No guesswork. Just incredibly talented resources.",
  },
  {
    imgPath: img3,
    imgClass: "home-new-fold-two-card-img2",
    title: "Science-Based Assessments",
    para: "Don’t leave critical hiring decisions to chance. We assess 15 core competencies, including desire, motivation, and coachability to predict success.",
  },
];

//array two
const arrayDataTwo = [
  {
    title: "Attract & Recruit Better Candidates",
    para: "92% of recommended candidates reach the top of the work force within their first year.",
  },
  {
    title: "Fill Open Positions Faster",
    para: "Slash your hiring time in half with pre-screened, qualified candidates who are interview-ready.",
  },
  {
    title: "Reduce Hiring Mistakes and Increase Retention",
    para: "Eliminate 96% of hiring mistakes and lower turnover by more than 33% with industry-leading assessment science.",
  },
];
export default function HomeNewFoldTwo() {
  /*======================================
           renderBlockOne
=======================================*/
  const renderBlockOne = () => {
    return (
      <div className="row mx-0 align-items-start flex-nowrap home-fold-one-card-outer-div">
        {arrayData.map((data, index) => (
          <div
            className={
              index === 2 ? "home-fold-two-card mr-0" : "home-fold-two-card"
            }
            key={index}
          >
            <img
              src={data.imgPath}
              alt={data.title}
              className={data.imgClass}
            />
            <h4 className="home-fold-two-card-title">{data.title}</h4>
            <p
              className={
                index === 0
                  ? "home-fold-two-card-para home-fold-two-card-para--one pt-25"
                  : "home-fold-two-card-para pt-25"
              }
            >
              {data.para}
            </p>
          </div>
        ))}
      </div>
    );
  };
  /*======================================
           renderBlockTwo
=======================================*/
  const renderBlockTwo = () => {
    return (
      <div className="home-new-fold-confidence-div">
        <h2 className="text-center home-our-service-title">
          Hire Rock Star Candidates with <br /> Confidence
        </h2>
        <p className="text-center home-new-fold-two-para pt-40">
          Hiring should never be done “on a hunch.” Data-driven companies are 3X
          more likely to make better decisions than those that rely on their
          <br />
          intuition. Aspire Pro taps into powerful data to screen candidates
          objectively and identify the true rockstars time and time again.{" "}
        </p>
        <div className="row mx-0 align-items-start flex-nowrap pt-135">
          <div className="col-6 p-0">
            <img
              src={img4}
              alt={"Hire Rock Star Candidates with Confidence"}
              className="home-fold-two-block-two-img"
            />
          </div>
          <div className="col-6">
            {arrayDataTwo.map((data, index) => (
              <div
                className={
                  index === 0
                    ? "pt-0 row mx-0 align-items-start flex-nowrap"
                    : "pt-67 row mx-0 align-items-start flex-nowrap"
                }
                key={index}
              >
                <img
                  src={check}
                  alt=""
                  className="home-new-fold-one-check-icon"
                />

                <div>
                  <h4 className="home-new-fold-two-confidience-text1">
                    {data.title}
                  </h4>
                  <p className="home-fold-two-card-para pt-25">{data.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="home-new-fold-two-div">
        <h2 className="text-center home-our-service-title">
          Why Take a Chance on Hiring the Wrong <br /> Resources
        </h2>
        <p className="text-center pt-60 home-new-fold-two-para">
          {" "}
          The unpleasant truth is that hiring elite talent is really difficult.
          84% of companies in India are greatly affected by their bad hiring
          choices. One <br /> bad hire can cost companies and employers an
          average of over INR 20 lakhs and the backlash stemming from making
          wrong hires can be severe <br /> with far reaching consequences.
          Everything from high turnover to lost revenue to a blow to your brand
          reputation can result from bad hires.
        </p>
      </div>
      {renderBlockOne()}
      <div className="row mx-0 align-items-center justify-content-center">
        <Link to="/why-choose-us">
          <div className="home-new-fold-two-btn cursor-pointer row mx-0 align-items-center justify-content-center">
            Learn what makes us Different
          </div>
        </Link>
      </div>
      {renderBlockTwo()}
    </>
  );
}
