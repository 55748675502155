import React from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
const c1 = "/img/home-new/customer.svg";
const c2 = "/img/home-new/customer-two.svg";
const c3 = "/img/home-new/customer-three.svg";
const c4 = "/img/home-new/customer-four.svg";

export default function HomeNewFoldFour() {
  const arrayData = [
    {
      imgPath: c1,
      clientName: "Alin Motiu",
      clientCompany: "mmmgood",
      clientPosition: "CPO",
      para: "mmmgood is a Geneva based in-home social dining booking platform for hosts and guests around the world. We partnered with Aspire to set up our research & development center in Bangalore, India. High quality Pre Qualified resource pool curated by Aspire Pro ensured that our interviewers spent little time interviewing. We were able to hire & onboard our CTO and build a qualified development team under him in quick time from this talent pool.",
    },
    {
      imgPath: c2,
      clientName: "Vijay Roy",
      clientCompany: "Applied AI Consulting (AAIC)",
      clientPosition: "CEO ",
      para: "AAIC is a cloud and DevOps consulting organization. This is the need of the hour and our faster growth depended on the availability of good Cloud, Python, DevOps candidates.  While working with other recruitment partners, the challenge was a lot of rejection in 1st round interviews. When we engaged with Aspire, we saw a complete U-turn in terms of %age of candidates moving up the interview ladder. In addition to Aspire’s hiring process, the biggest game-changer was 'Aspire Pro'. With scores of good questions set at varying difficulty levels, the candidates who reached the 1st round interview were already too good to get hired. We are now thinking of getting away from the 2nd round discussion since the quality of the pipeline is awesome. Thanks to Anurag and the team for bringing Aspire Pro!",
    },
    {
      imgPath: c3,
      clientName: "Monali Haware",
      clientCompany: "MontaVista",
      clientPosition: "HR Manager",
      para: "Our partnership with Aspire has been integral to our hiring success in India. There are few professionals in this industry who bring this level of competence to the table. Aspire’s Ability to build high quality pipe lines for multiple requirements parallelly, unique candidate insights and superior onboarding is a winner.",
    },
    {
      imgPath: c4,
      clientName: "Ananth M",
      clientCompany: "Spoton",
      clientPosition: "HR Manager",
      para: "Have been associated with Aspire for more than 4 years, they have been a fantastic vendor. Highly skilled & experienced team which does an excellent search for closing critical niche positions. We've relied on them exclusively for IT, Data Science related requirements since 2018 and look forward to continuing that relationship.",
    },
  ];

  /*====================================
               renderSlider
   ====================================*/
  const renderSlider = () => {
    return (
      <div className="home-new-slider-div">
        <CarouselProvider
          naturalSlideWidth={100}
          //naturalSlideHeight={40}
          naturalSlideHeight={30}
          totalSlides={4}
          interval={5000}
          isPlaying={true}
          playDirection={"forward"}
          step={1}
          infinite={true}
        >
          <Slider>
            {arrayData.map((data, index) => (
              <Slide index={index} key={index}>
                <div className="row mx-0 align-items-center flex-nowrap justify-content-between home-new-fold-four-slide">
                  <div className="row mx-0 align-items-center flex-nowrap">
                    <img
                      src={data.imgPath}
                      alt={data.clientName}
                      className="home-new-fold-four-client-img"
                    />
                    <div>
                      <h4 className="home-new-fold-four-client-name">
                        {data.clientName}
                      </h4>
                      <h5 className="home-new-fold-four-client-company-name">
                        {data.clientCompany}
                      </h5>
                      <h5 className="home-new-fold-four-client-position">
                        {data.clientPosition}
                      </h5>
                    </div>
                  </div>

                  <p className="home-new-fold-four-client-description">
                    <span>
                      <img
                        src={"/img/icon/opening-quote.svg"}
                        alt=""
                        className="opening-quote"
                      />
                    </span>
                    {data.para}
                    <span>
                      <img
                        src={"/img/icon/closing-quote.svg"}
                        alt=""
                        className="closing-quote"
                      />
                    </span>
                  </p>
                </div>
              </Slide>
            ))}
          </Slider>
          <div className="text-center">
            <DotGroup dotNumbers={true} className="slide_dotgroup" />{" "}
          </div>
        </CarouselProvider>
      </div>
    );
  };
  return (
    <div>
      <h2 className="home-our-service-title text-center pt-80">
        What our customers are saying
      </h2>
      {renderSlider()}
    </div>
  );
}
