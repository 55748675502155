import React from "react";
//img
const img1 = "/img/contact/bag.png";
const img2 = "/img/contact/link.png";
const img3 = "/img/contact/star.png";
const img4 = "/img/contact/map.png";
const img5 = "/img/contact/location.png";

export default function ContactDetails() {
  return (
    <div>
      {/* block one */}
      <h2 className="font-50-poppins">Contact Us</h2>
      <div className="row mx-0 pt-74 align-items-start flex-nowrap">
        <div className="contact-left-img-div">
          <img src={img1} alt="jobs" className="job-img" />
        </div>
        <div>
          <h3 className="contact-left-title">For Jobs</h3>
          <div className="row mx-0 align-items-start">
            <img src={img2} alt="link" className="link-img" />
            <a
              href="mailto:jobs@aspireandsucceedhr.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5 className="contact-left-link">
                {" "}
                jobs@aspireandsucceedhr.com
              </h5>
            </a>
          </div>
        </div>
      </div>
      {/* block two */}
      <div className="row mx-0 align-items-start pt-60">
        <div className="contact-left-img-div">
          <img src={img3} alt="services" className="star-img" />
        </div>
        <div>
          <h3 className="contact-left-title">For Services</h3>
          <div className="row mx-0 align-items-start">
            <img src={img2} alt="link" className="link-img" />
            <a
              href="mailto:anurag.s@aspireandsucceedhr.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5 className="contact-left-link">
                anurag.s@aspireandsucceedhr.com
              </h5>
            </a>
          </div>
        </div>
      </div>
      {/* block three */}
      <div className="row mx-0 align-items-start pt-60">
        <div className="contact-left-img-div">
          <img src={img4} alt="address" className="map-img" />
        </div>
        <div>
          <h3 className="contact-left-title">Address</h3>
          <div className="row mx-0 align-items-start pt-36">
            <img src={img5} alt="location" className="location-img" />
            <h5 className="contact-left-text-3">
              303, West Wing, Aurora Towers MG Road, <br />
              Pune
            </h5>
          </div>
          <div className="row mx-0 align-items-start pt-30">
            <img src={img5} alt="location" className="location-img" />
            <h5 className="contact-left-text-3">
              No 2,Central Facility Bldg. A.P.M.C, Sector 19, <br />
              Vashi, Navi Mumbai.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
