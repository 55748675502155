import React, { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WebNavbar from "../../WebNavbar";
import { useHistory } from "react-router-dom";

//img
const img1 = "/img/traning/fold1.png";
const img2 = "/img/traning/fold2.png";
const logo1 = "/img/traning/t1.png";
const logo2 = "/img/traning/t2.png";
const logo3 = "/img/traning/t3.png";
const logo4 = "/img/traning/t4.png";
const logo5 = "/img/traning/t5.png";
const logo6 = "/img/traning/t6.png";
const logo7 = "/img/traning/t7.png";
const logo8 = "/img/traning/t8.png";
const logo9 = "/img/traning/t9.png";
const back = "/img/icon/back.png";

//array
const dummyData = [
  {
    imgPath: logo1,
    imgClass: "traning-logo-img1",
    name: "Management & Leadership",
  },
  {
    imgPath: logo2,
    imgClass: "traning-logo-img2",
    name: "Mechanical",
  },
  {
    imgPath: logo3,
    imgClass: "traning-logo-img3",
    name: "Electrical",
  },
  {
    imgPath: logo4,
    imgClass: "traning-logo-img4",
    name: "Maintenance & Reliability Engineering",
  },
  {
    imgPath: logo5,
    imgClass: "traning-logo-img5",
    name: "HSE",
  },
  {
    imgPath: logo6,
    imgClass: "traning-logo-img6",
    name: "Marketing & Sales",
  },
  {
    imgPath: logo7,
    imgClass: "traning-logo-img7",
    name: "OIl & Gas Technology",
  },
  {
    imgPath: logo8,
    imgClass: "traning-logo-img8",
    name: "Telecom",
  },
  {
    imgPath: logo9,
    imgClass: "traning-logo-img9",
    name: "Energy Management",
  },
];
export default function Training() {
  //useEffect
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const history = useHistory();

  /*==============================
            routeChange
  ==============================*/
  const routeChange = () => {
    history.goBack();
  };

  /*==============================
           renderBlock1
  ==============================*/
  const renderBlock1 = () => {
    return (
      <div className="row mx-0 training-card-div">
        {dummyData.map((data, key) => (
          <div key={key} className="training-card">
            <div className="training-card-img-div">
              <img
                src={data.imgPath}
                alt="training"
                className={data.imgClass}
              />
            </div>
            <div className="training-card-text-div">
              <h5 className="training-card-text">{data.name}</h5>
            </div>
            {/*<h5 className="learn-more-text">Learn more</h5>*/}
          </div>
        ))}
      </div>
    );
  };

  /*==============================
           render
  ==============================*/
  return (
    <div className="bg-div">
      <WebNavbar />
      <div className="training-top-div">
        <div className="row mx-0 justify-content-between align-items-center flex-nowrap">
          <div className="home-fold1-left-div">
            <div
              className="row mx-0 align-items-center go-back-div"
              onClick={routeChange}
            >
              <img src={back} alt="go-back" className="go-back-img" />
              <span className="go-back-text">Go back</span>
            </div>

            <h3 className="font-24-DMsans-bold color-gray-opacity-5">
              Give your prospects the best training
            </h3>
            <h2 className="font-50-poppins">Training</h2>
            <h5 className="home-font-20-mulish pt-44">
              We service several clients in India, Dubai- UAE and Doha- Qata
              primarily in Oil and Gas, EPC, Manufacturing and other process
              industries. s
            </h5>
            <h5 className="home-font-20-mulish pt-20">
              As an expert in professional training, we draw on our years of
              worldwide experience to provide effective learning and development
              opportunities. We make a difference to individuals, teams and
              businesses, nurturing talent and enabling continuous
              organisational progression.
            </h5>
          </div>
          <div className="training-fold1-img-div">
            <img src={img1} alt="training" className="img-fluid" />
          </div>
        </div>
        <h5 className="training-36-blue-font pt-50">
          Our specialists partner with course participants, identifying
          <br />
          improvement objectives and supporting the professional journey.
        </h5>
        <div className="row mx-0 align-items-center justify-content-between flex-nowrap pt-60">
          <div className="training-fold2-img-div">
            <img src={img2} alt="training fold 2" className="img-fluid" />
          </div>
          <div className="home-fold2-right-div">
            <h5 className="home-font-20-mulish ">
              A specialist team of 500 independent instructors, each a
              recognized subject matter expert, with a wide variety of technical
              and worldwide experience of more than 25 Years in their core
              sector. Each subjected to rigorous pre-screening and periodic
              re-evaluation.
            </h5>
            <h5 className="home-font-20-mulish pt-20">
              Course curricula and materials are constantly evaluated, Peer
              reviewed and updated to meet the changing needs of our clients and
              the industries in which they compete.
            </h5>
            <h5 className="home-font-20-mulish pt-20">
              Expert emphasis on Implementation of training, experts call and
              review delegates for 2 months after the training programs.
            </h5>
          </div>
        </div>
        <div>
          <h5 className="home-font-20-mulish pt-135">
            Enhancing processes, systems and skills is fundamental to your
            ongoing success and sustained growth. We enable you to continuously
            improve, transforming your services and value chain by increasing
            performance, managing risks, better meeting stakeholder
            requirements, and managing sustainability.
          </h5>
        </div>
        <h5 className="training-36-blue-font blue-color pt-72">
          Our curriculum includes 700, introductory to advanced training
          <br />
          programs showcasing best practices in
        </h5>
      </div>
      {renderBlock1()}
      {/*<div className="training-footer-div">*/}
      <Webfooter />
      {/*</div>*/}
    </div>
  );
}
