import React from "react";
import HomeNewFoldFour from "../home-new/HomeNewFoldFour";
const icon1 = "/img/about/icon1.png";

export default function AboutUsNewFoldThree() {
  return (
    <div>
      <div className="row mx-0 align-items-center justify-content-between flex-nowrap about-us-new-fold-three-section">
        <div>
          <div className="row mx-0 align-items-center pt-40">
            <div className="no-show-management-icon-div">
              <img
                src={icon1}
                alt="Our value proposition"
                className="img-fluid"
              />
            </div>

            <h5 className="about-fold2-dm-sans-24">Our value proposition</h5>
          </div>
          <h5 className="home-font-20-mulish home-font-20-mulish--about pt-60">
            What truly sets us apart is our approach &amp; velocity. Our success
            has been built upon maintaining long-term relationships with our
            clients and providing them with globally recognised HR solutions.
          </h5>
        </div>
        <img
          src={"/img/about-new/proposition-img.svg"}
          alt="about us fold2"
          className="about-us-new-fold3-img"
        />
      </div>
      <HomeNewFoldFour />
    </div>
  );
}
