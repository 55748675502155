import React, { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WebNavbar from "../../WebNavbar";
import HomeNewFoldFour from "./HomeNewFoldFour";
import HomeNewFoldOne from "./HomeNewFoldOne";
import HomeNewFoldThree from "./HomeNewFoldThree";
import HomeNewFoldTwo from "./HomeNewFoldTwo";

//content
//const para =
// "We aim to make the hiring process as simple and transparent as possible from start to finish.";
//const title = "How Aspire Test portal Works";
//const title = "How AspireXPro Works";
//const stepOneTitle =
//  "Register for Your Free Account and Work with a Talent Advisor to Define Your Ideal Candidate Profile and Hiring Criteria";

export default function HomeNew() {
  //useEffect

  useEffect(() => {
    var url = window.location.toString();
    if (url.indexOf("#") > 0) {
      var clean_url = url.substring(0, url.indexOf("#"));
      window.history.replaceState({}, document.title, clean_url);
    } else {
      window.scrollTo(0, 0);
    }
    // window.history.replaceState(null, null, " ");
  });

  return (
    <div>
      <WebNavbar activeLink="home" />
      <HomeNewFoldOne />
      <HomeNewFoldTwo />
      <div id="howAspireXProWorks">
        <HomeNewFoldThree
          //  para={para} title={title} stepOneTitle={stepOneTitle}
          imgOne={"/img/home-new/aspire-test-step-one-new.svg"}
          imgFour={"/img/home-new/aspire-test-step-four-new.svg"}
          stepTwoPara={
            "We use sophisticated technology along with a national network of experienced recruiters to filter through candidates and find the best of the best in your industry."
          }
          stepFourPara={
            "We put you in touch with elite resources by sending them right to your inbox for feedback and approval. Conveniently browse through candidates and choose the ones that have what you’re looking for. All the while our recruiting platform makes managing interview scheduling and feedback channels a breeze."
          }
        />
      </div>
      <HomeNewFoldFour />
      {/*(<div className="pt-500">*/}
      <Webfooter />
      {/*</div>*/}
    </div>
  );
}
