import React from "react";
import WebNavbar from "../../WebNavbar";
import Webfooter from "../../Webfooter";
import HireTechLeadersFirstFold from "./HireTechLeadersFirstFold";
import HireTechLeadersProcessFold from "./HireTechLeadersProcessFold";
import HireTechLeadersApproachFold from "./HireTechLeadersApproachFold";
//import HireTechLeadersSpecializeInFold from "./HireTechLeadersSpecializeInFold";
// import HireTechLeadersGetStarted from "./HireTechLeadersGetStarted";
import HomeNewFoldThree from "../home-new/HomeNewFoldThree";
import HireTechLeadersSpecializeInFoldNew from "./HireTechLeadersSpecializeInFoldNew";

export default function HireTechLeaders() {
  return (
    <>
      <WebNavbar />
      <HireTechLeadersFirstFold />
      <HireTechLeadersProcessFold
        title={"We Make Hiring Technology Talent Easy"}
      />
      <HireTechLeadersApproachFold
        para1={
          "We go beyond the basic job description and define the skills, experience, capabilities, and behavioral traits candidates need to be successful in your development ecosystem and team culture"
        }
        para2={
          "Leverage the power of our national recruiter network and peer-to-peer referral program to tap into the top 20% of developers in the market. This unique crowdsourcing approach enables us to fill your open roles faster."
        }
      />
      {/* <HireTechLeadersGetStarted /> */}
      <HomeNewFoldThree
        isFiveFoldBgWhite={true}
        imgOne={"/img/home-new/aspire-test-step-one-new.svg"}
        imgFour={"/img/home-new/leader-test-step-four.svg"}
        stepTwoPara={
          "We use sophisticated technology along with a national network of experienced technology recruiters to filter through candidates and find the best of the best in your industry."
        }
        stepFourPara={
          "We put you in touch with elite developers by sending them right to your inbox for feedback and approval. Conveniently browse through candidates and choose the ones that have what you’re looking for. All the while our recruiting platform makes managing interview scheduling and feedback channels a breeze."
        }
        //para=""
        //title="How to Get Started"
        //stepOneTitle="Work With a Dedicated Talent Advisor to Define Your Ideal Candidate Profile and Hiring Criteria"
      />
      {/*<HireTechLeadersSpecializeInFold />*/}
      <HireTechLeadersSpecializeInFoldNew />
      <div className="web-footer-div-tech-leaders-page">
        <Webfooter />
      </div>
    </>
  );
}
