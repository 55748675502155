import React from "react";
import { useEffect } from "react";
import Webfooter from "../../Webfooter";
import AboutUsNewFoldFour from "./AboutUsNewFoldFour";
import AboutUsNewFoldOne from "./AboutUsNewFoldOne";
import AboutUsNewFoldThree from "./AboutUsNewFoldThree";
import AboutUsNewFoldTwo from "./AboutUsNewFoldTwo";

export default function AboutUsNew() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <AboutUsNewFoldOne />
      <AboutUsNewFoldTwo />
      <AboutUsNewFoldThree />
      <AboutUsNewFoldFour />
      <Webfooter />
    </div>
  );
}
