import React, { useEffect } from "react";
import Webfooter from "../../Webfooter";
import WebNavbar from "../../WebNavbar";
import { useHistory } from "react-router-dom";
//img
const img1 = "/img/hrd/fold1.png";
const img2 = "/img/hrd/fold2-new.png";
const icon1 = "/img/hrd/icon1.png";
const icon2 = "/img/hrd/icon2.png";
const icon3 = "/img/hrd/icon3.png";
const icon4 = "/img/hrd/icon4.png";
const icon5 = "/img/hrd/icon5.png";
const back = "/img/icon/back.png";

//array
const dummyData = [
  {
    imgPath: icon1,
    name: "HR Manual & Policy Implementation",
    desc: "Our Empaneled consultants have created and implemented best practices for leading corporations & startups.",
  },
  {
    imgPath: icon2,
    name: "Hiring",
    desc: "Use our Skill assessment and No-show tools to accelerate talent acquisition and create higher retention",
  },
  {
    imgPath: icon3,
    name: "Attendance & Leave Management",
    desc: "Single window access to attendance & leave logs including detailed employee analytics.",
  },
  {
    imgPath: icon4,
    name: "Payroll",
    desc: "Manage all payroll activities from a single screen, Create salary structures, Declarations ",
  },
  {
    imgPath: icon5,
    name: "Expense",
    desc: "Track and manage expenses. Integrate with Bank and pay directly from dash board ",
  },
];
const dummyData2 = [
  //{
  //  name: "Employee Engagement",
  //  desc:
  //    "Personalized greetings, online counselling sessions, merchandise with company branding.",
  //},
  {
    name: "Employee Engagement for Work from Home Staff",
    //desc:
    //  "Designed to knit the work force into a common thread. Create a sense of purpose and belonging via our remote group activities and games",
    desc: "Designed to knit the work force into a common thread. Create a sense of purpose and belonging & higher productivity via our remote activities, auto pilot surveys based on life cycle and various other employee engagement tools designed for WFH resources ",
  },
];

export default function HRDManagement() {
  //useEffect
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const history = useHistory();

  /*====================================
             routeChange
  =====================================*/
  const routeChange = () => {
    history.goBack();
  };

  /*====================================
             renderBlock2
  =====================================*/
  const renderBlock2 = () => {
    return (
      <div className="row mx-0 justify-content-center hrd-card-div">
        {dummyData.map((data, key) => (
          <div key={key}>
            {key === 3 ? (
              <div className="staffing-card staffing-card--hrd  mr-130">
                <div className="staffing-card-img-div">
                  <div className="staffing-card-img staffing-card-img--hrd">
                    <img
                      src={data.imgPath}
                      className="img-fluid"
                      alt={data.name}
                    />
                  </div>
                </div>
                <div className="staffing-card-text-div staffing-card-text-div--hrd">
                  <h5 className="staffing-card-text">{data.name}</h5>
                </div>
                <div className="staffing-card-desc-div staffing-card-desc-div--hrd">
                  <h5 className="staffing-card-desc">{data.desc}</h5>
                </div>
              </div>
            ) : (
              <div className="staffing-card staffing-card--hrd mr-40">
                <div className="staffing-card-img-div staffing-card-img-div--hrd">
                  <div className="staffing-card-img staffing-card-img--hrd">
                    <img
                      src={data.imgPath}
                      className="img-fluid"
                      alt={data.name}
                    />
                  </div>
                </div>
                <div className="staffing-card-text-div staffing-card-text-div--hrd">
                  <h5 className="staffing-card-text">{data.name}</h5>
                </div>
                <div className="staffing-card-desc-div staffing-card-desc-div--hrd">
                  <h5 className="staffing-card-desc">{data.desc}</h5>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  /*==========================================
                renderBlock3
  =========================================*/
  const renderBlock3 = () => {
    return (
      <div className="row mx-0 align-items-center justify-content-between flex-nowrap hrd-fold3-div">
        <div>
          {/*<h5 className="hrd-36-font">We can also help with</h5>*/}
          <h5 className="hrd-36-font">In demand</h5>
          <div className="pt-50">
            {dummyData2.map((data, key) => (
              <div key={key} className="hrd-card">
                <h5 className="hrd-card-text">{data.name}</h5>
                <h5 className="hrd-card-desc">{data.desc}</h5>
              </div>
            ))}
          </div>
        </div>
        <div className="hrd-fold2-img-div">
          <img src={img2} alt="We can also help with" className="img-fluid" />
        </div>
      </div>
    );
  };
  /*=============================================
                 render
=============================================*/
  return (
    <div>
      <WebNavbar />
      <div className="hrd-top-div hrd-bg-div">
        <div
          className="row mx-0 align-items-center go-back-div"
          onClick={routeChange}
        >
          <img src={back} alt="go-back" className="go-back-img" />
          <span className="go-back-text">Go back</span>
        </div>
        <h3 className="font-24-DMsans-bold">Complete</h3>
        <h2 className="font-50-poppins">HRD Management</h2>
        <div className="row mx-0 justify-content-center pt-116">
          <div className="hrd-img-div">
            <img src={img1} alt="Staffing Solutions" className="img-fluid" />
          </div>
        </div>
      </div>
      {renderBlock2()}
      {renderBlock3()}
      <Webfooter />
    </div>
  );
}
