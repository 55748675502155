import React from "react";
import { Link } from "react-router-dom";

const logo = "/img/logo/header-aspire-logo.png";

const WebNavbar = ({ activeLink, isFooterLogo }) => {
  return (
    <div className="row mx-0 align-items-center justify-content-between aspire-navbar flex-nowrap">
      <Link to="/">
        <img
          src={isFooterLogo ? "/img/logo/footer-aspire-logo.png" : logo}
          alt="aspire"
          className="aspire-header-logo cursor-pointer"
        />
      </Link>
      <nav className="row mx-0 align-items-center justify-content-end">
        <ul className="row mx-0 align-items-center">
          <li>
            <Link to="/">
              <h5
                className={
                  activeLink === "home"
                    ? "nav-link nav-link--active"
                    : "nav-link"
                }
              >
                Home
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <h5
                className={
                  activeLink === "about"
                    ? "nav-link nav-link--active"
                    : "nav-link"
                }
              >
                About Us
              </h5>
            </Link>
          </li>
          <Link to="/our-services">
            <li>
              <h5
                className={
                  activeLink === "our-service"
                    ? "nav-link nav-link--active"
                    : "nav-link"
                }
              >
                Our Services
              </h5>
            </li>
          </Link>
          <li>
            {/* <Link to="/"> */}
            <a
              href={"https://aspirexpro.wordpress.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <h5
                className={
                  activeLink === "blog"
                    ? "nav-link nav-link--active"
                    : "nav-link"
                }
              >
                Blog
              </h5>
            </a>
            {/* </Link> */}
          </li>
          <a
            href={"https://app.aspireandsucceedhr.com/#!/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* nav-link--our-test */}
            <li>
              <h5 className="nav-link">{/*Our Test Portal*/}Aspire Pro</h5>
            </li>
          </a>
        </ul>
        <div className="row mx-0">
          <Link to="/contact">
            <div className="web-navbar-button">
              <h5 className="web-navbar-button-text">
                {/*Contact Us*/}Hire Faster - Contact us
              </h5>
            </div>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default WebNavbar;
