import React from "react";

//img
const img4 = "/img/home/about1.png";
const img5 = "/img/home/about2.png";
const img6 = "/img/home/about3.png";
const img7 = "/img/home/about4.png";

export default function OurServicesFoldTwo() {
  /*=========================================================
                     renderBlock1
==========================================================*/
  const renderBlock1 = () => {
    return (
      <div className="row mx-0 justify-content-center flex-nowrap align-items-center  pt-160">
        <div className="about-img1-div">
          <img src={img4} alt="about we have" className="img-fluid" />
        </div>
        <div className="text-right">
          <h3 className="font-24-DMsans-bold font-24-DMsans-bold-home-color-gray">
            {" "}
            We have
          </h3>
          <h2 className="home-about-36-font">
            1 in 3 Selection <br />
            Ratio in{" "}
            <span className="text-right color-blue">
              {" "}
              lateral
              <br />
              hiring
            </span>
          </h2>
        </div>
      </div>
    );
  };

  /*=========================================================
                     renderBlock2
==========================================================*/
  const renderBlock2 = () => {
    return (
      <div className="row mx-0 justify-content-center flex-nowrap align-items-center our-services-reduce-your-workload">
        <div>
          <h3 className="font-24-DMsans-bold font-24-DMsans-bold-home-color-gray">
            {" "}
            We can
          </h3>
          <h2 className="home-about-36-font">
            Reduce your workload
            <br />
            by 100%
          </h2>
        </div>
        <div className="about-img2-div">
          <img src={img5} alt="about we have" className="img-fluid" />
        </div>
      </div>
    );
  };

  /*=========================================================
                     renderBlock3
==========================================================*/

  const renderBlock3 = () => {
    return (
      <div className="row mx-0 justify-content-center flex-nowrap align-items-center our-services-better-retention-div">
        <div className="about-img3-div">
          <img src={img6} alt="about we also have" className="img-fluid" />
        </div>
        <div className="text-right">
          <h3 className="font-24-DMsans-bold font-24-DMsans-bold-home-color-gray">
            {" "}
            We also have
          </h3>
          <h2 className="home-about-36-font">
            Better Retention
            <br />
            Ratio, use our
            <br />
            <span className="color-blue"> virtual assistant tool</span>
          </h2>
        </div>
      </div>
    );
  };

  /*=========================================================
                     renderBlock4
==========================================================*/
  const renderBlock4 = () => {
    return (
      <div className="row mx-0 flex-nowrap justify-content-center align-items-center our-services-pre-screened-candidates-div">
        <div>
          <h3 className="font-24-DMsans-bold font-24-DMsans-bold-home-color-gray">
            {" "}
            and provide
          </h3>
          <h2 className="home-about-36-font">
            Pre Screened Candidates
            <br />
            <span className="color-blue">via assessment tool</span>
          </h2>
        </div>
        <div className="about-img4-div">
          <img src={img7} alt="about we have" className="img-fluid" />
        </div>
      </div>
    );
  };

  /*=========================================================
                     render
==========================================================*/
  return (
    <div>
      <h3 className="our-services-fold-two-title text-center">
        What more can we do?
      </h3>
      <h5 className="our-services-fold-two-subtitle text-center">Well,</h5>
      <div className="pb-100">
        {renderBlock1()}
        {renderBlock2()}
        {renderBlock3()}
        {renderBlock4()}
      </div>
    </div>
  );
}
